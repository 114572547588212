const iconSizes = {
  'icon-s': {
    width: '16px',
    height: '16px',
  },
  'icon-m': {
    width: '24px',
    height: '24px',
  },
  'icon-l': {
    width: '32px',
    height: '32px',
  },
  'icon-xl': {
    width: '48px',
    height: '48px',
  },
}

export { iconSizes }
