import * as actionTypes from './constants'

export function uploadProjectPicture(file, projectId, logoType, retries = 1) {
  return {
    type: actionTypes.UPLOAD_PROJECT_PICTURE,
    file,
    projectId,
    logoType,
    retries,
  }
}

export function deleteProjectPicture(projectId, logoType, retries = 1) {
  return {
    type: actionTypes.DELETE_PROJECT_PICTURE,
    projectId,
    logoType,
    retries,
  }
}

export const projectPictureUploaded = (logoType) => ({
  type: actionTypes.PROJECT_PICTURE_UPLOADED,
  logoType,
})
export const projectPictureUploadError = (error) => ({
  type: actionTypes.PROJECT_PICTURE_UPLOAD_ERROR,
  error,
})

export const getProjectPicture = (projectId) => ({
  type: actionTypes.GET_PROJECT_PICTURE,
  projectId,
})
export const setProjectPicture = (projectPicture) => ({
  type: actionTypes.SET_PROJECT_PICTURE,
  projectPicture,
})
export const getProjectPictureError = (error) => ({
  type: actionTypes.GET_PROJECT_PICTURE_ERROR,
  error,
})

export const clearProjectDetails = () => ({
  type: actionTypes.CLEAR_PROJECT_DETAILS,
})
export const getProjectDetails = (projectId, retries = 1) => ({
  type: actionTypes.GET_PROJECT_DETAILS,
  projectId,
  retries,
})
export const setProjectDetails = (project) => ({
  type: actionTypes.SET_PROJECT_DETAILS,
  project,
})
export const getProjectDetailsError = (error) => ({
  type: actionTypes.GET_PROJECT_DETAILS_ERROR,
  error,
})

export const saveProjectDetails = (projectId, fields, parentId) => ({
  type: actionTypes.SAVE_PROJECT_DETAILS,
  projectId,
  fields,
  parentId,
})
export const projectDetailsSaved = (projectId, fields, parentId) => ({
  type: actionTypes.PROJECT_DETAILS_SAVED,
  projectId,
  fields,
  parentId,
})
export const saveProjectDetailsError = (error) => ({
  type: actionTypes.SAVE_PROJECT_DETAILS_ERROR,
  error,
})

// pin categories
export function clearPinCategories() {
  return {
    type: actionTypes.CLEAR_PIN_CATEGORIES,
  }
}
export function setPinCategories(pinCategories) {
  return {
    type: actionTypes.SET_PIN_CATEGORIES,
    pinCategories,
  }
}

export function pinCategoryUpdated(pinCategory, index) {
  return {
    type: actionTypes.PIN_CATEGORY_UPDATED,
    pinCategory,
    index,
  }
}
export function createAndUpdatePinCategories(
  newPinCategories,
  updatedPinCategories,
  projectId,
  retries = 1,
) {
  return {
    type: actionTypes.CREATE_AND_UPDATE_PIN_CATEGORIES,
    newPinCategories,
    updatedPinCategories,
    projectId,
    retries,
  }
}
export function pinCategoryCreated(pinCategory) {
  return {
    type: actionTypes.PIN_CATEGORY_CREATED,
    pinCategory,
  }
}
export function pinCategoriesCreatedAndUpdated() {
  return {
    type: actionTypes.PIN_CATEGORIES_CREATED_AND_UPDATED,
  }
}
export function createAndUpdatePinCategoriesError(error) {
  return {
    type: actionTypes.CREATE_AND_UPDATE_PIN_CATEGORIES_ERROR,
    error,
  }
}

export function getPinCategories(projectId, retries = 1) {
  return {
    type: actionTypes.GET_PIN_CATEGORIES,
    projectId,
    retries,
  }
}
export function getPinCategoriesError(error) {
  return {
    type: actionTypes.GET_PIN_CATEGORIES_ERROR,
    error,
  }
}

export function getTemplatesOfProject(projectId, page = 0) {
  return {
    type: actionTypes.GET_TEMPLATES_OF_PROJECT,
    projectId,
    page,
  }
}
export function getTemplatesOfProjectDone(templates, totalPages) {
  return {
    type: actionTypes.GET_TEMPLATES_OF_PROJECT_DONE,
    templates,
    totalPages,
  }
}
export function getTemplatesOfProjectError(error) {
  return {
    type: actionTypes.GET_TEMPLATES_OF_PROJECT_ERROR,
    error,
  }
}

export const getFields = (projectId) => ({
  type: actionTypes.GET_FIELDS,
  projectId,
})

export const getFieldsDone = (pinFields) => ({
  type: actionTypes.GET_FIELDS_DONE,
  pinFields,
})

export const getFieldsError = (error) => ({
  type: actionTypes.GET_FIELDS_ERROR,
  error,
})

export const updatePinField = (field, retries = 1) => ({
  type: actionTypes.UPDATE_PIN_FIELD,
  field,
  retries,
})
export const updatePinFieldDone = (field) => ({
  type: actionTypes.UPDATE_PIN_FIELD_DONE,
  field,
})
export const updatePinFieldError = (error) => ({
  type: actionTypes.UPDATE_PIN_FIELD_ERROR,
  error,
})

export const createPinField = (projectId, newFieldDetails, retries = 1) => ({
  type: actionTypes.CREATE_PIN_FIELD,
  projectId,
  newFieldDetails,
  retries,
})
export const createPinFieldDone = (newFieldDetails) => ({
  type: actionTypes.CREATE_PIN_FIELD_DONE,
  newFieldDetails,
})
export const clearCreatedField = () => ({
  type: actionTypes.CLEAR_CREATED_FIELD,
})
export const createPinFieldError = (error) => ({
  type: actionTypes.CREATE_PIN_FIELD_ERROR,
  error,
})
