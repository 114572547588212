import { createSelector } from 'reselect'

const selectProjectSettingsDomain = (state) => state.get('projectSettings')

const makeSelectProjectPictureUploaded = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('projectPictureUploaded'),
  )
const makeSelectProjectPictureUploadError = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('projectPictureUploadError'),
  )

const makeSelectProjectPictureUploading = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('projectPictureUploading'),
  )
const makeSelectProjectReportPictureUploaded = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('projectReportPictureUploaded'),
  )
const makeSelectProjectReportPictureUploadError = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('projectReportPictureUploadError'),
  )
const makeSelectProjectReportPictureUploading = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('projectReportPictureUploading'),
  )

const makeSelectProjectDetails = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate?.get('projectDetails'),
  )

const makeSelectProjectName = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.has('projectDetails') ? substate.get('projectDetails').name : '',
  )

const makeSelectProjectPermissions = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.has('projectDetails')
      ? substate.get('projectDetails').permissions
      : [],
  )

const makeSelectProjectDetailsCostsLabels = () =>
  createSelector(selectProjectSettingsDomain, (substate) => {
    if (substate.has('projectDetails')) {
      const {
        oneStarLabel = '',
        twoStarLabel = '',
        threeStarLabel = '',
        fourStarLabel = '',
        fiveStarLabel = '',
        active = false,
        name = '',
      } = substate.has('projectDetails')
        ? substate.get('projectDetails').costDefinition || {}
        : {}

      return active
        ? {
            name,
            labels: [
              oneStarLabel,
              twoStarLabel,
              threeStarLabel,
              fourStarLabel,
              fiveStarLabel,
            ],
          }
        : null
    }
    return null
  })
const makeSelectProjectDetailsPriorityLabels = () =>
  createSelector(selectProjectSettingsDomain, (substate) => {
    const {
      oneStarLabel = '',
      twoStarLabel = '',
      threeStarLabel = '',
      fourStarLabel = '',
      fiveStarLabel = '',
      active = false,
      name = '',
    } = substate.has('projectDetails')
      ? substate.get('projectDetails').priorityDefinition || {}
      : {}
    return active
      ? {
          name,
          labels: [
            oneStarLabel,
            twoStarLabel,
            threeStarLabel,
            fourStarLabel,
            fiveStarLabel,
          ],
        }
      : null
  })
const makeSelectGetProjectDetailsError = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('getProjectDetailsError'),
  )

const makeSelectSavingProjectDetails = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('savingProjectDetails'),
  )
const makeSelectSavingProjectDetailsError = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('savingProjectDetailsError'),
  )

const makeSelectSavingParentFolder = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('savingParentFolder'),
  )

const makeSelectSavingParentFolderError = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('savingParentFolderError'),
  )

const makeSelectSavingPinCategory = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('savingPinCategory'),
  )
const makeSelectPinCategoryError = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('pinCategoryError'),
  )
const makeSelectPinCategories = () =>
  createSelector(
    selectProjectSettingsDomain,
    (substate) => substate.get('projectDetails')?.categoryList,
  )
const makeSelectGettingPinCategories = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('gettingPinCategories'),
  )
const makeSelectPinCategoriesSettings = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('pinCategories'),
  )
const makeSelectOrganisationId = () =>
  createSelector(
    selectProjectSettingsDomain,
    (substate) => substate.get('projectDetails')?.organisationId,
  )

const makeSelectGettingTemplatesOfProject = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('gettingTemplatesOfProject'),
  )
const makeSelectGetTemplatesOfProjectError = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('getTemplatesOfProjectError'),
  )
const makeSelectTemplatesOfProject = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('templatesOfProject')?.toJS(),
  )
const makeSelectTemplatesOfProjectTotalPages = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('templatesOfProjectTotalPages'),
  )
const makeSelectGettingField = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('gettingFields'),
  )
const makeSelectFields = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('FieldsInProject'),
  )
const makeSelectUpdatingPinField = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('updatingPinField'),
  )
const makeSelectCreatingPinField = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('creatingPinField'),
  )
const makeSelectCreatedPinField = () =>
  createSelector(selectProjectSettingsDomain, (substate) =>
    substate.get('createdField'),
  )
export {
  selectProjectSettingsDomain,
  makeSelectProjectPictureUploaded,
  makeSelectProjectPictureUploadError,
  makeSelectProjectPictureUploading,
  makeSelectProjectReportPictureUploaded,
  makeSelectProjectReportPictureUploadError,
  makeSelectProjectReportPictureUploading,
  makeSelectProjectDetails,
  makeSelectGetProjectDetailsError,
  makeSelectSavingProjectDetails,
  makeSelectSavingProjectDetailsError,
  makeSelectSavingParentFolder,
  makeSelectSavingParentFolderError,
  makeSelectSavingPinCategory,
  makeSelectPinCategoryError,
  makeSelectPinCategories,
  makeSelectPinCategoriesSettings,
  makeSelectProjectDetailsCostsLabels,
  makeSelectProjectDetailsPriorityLabels,
  makeSelectOrganisationId,
  makeSelectProjectPermissions,
  makeSelectGettingTemplatesOfProject,
  makeSelectGetTemplatesOfProjectError,
  makeSelectTemplatesOfProject,
  makeSelectProjectName,
  makeSelectTemplatesOfProjectTotalPages,
  makeSelectGettingPinCategories,
  makeSelectFields,
  makeSelectGettingField,
  makeSelectUpdatingPinField,
  makeSelectCreatingPinField,
  makeSelectCreatedPinField,
}
